<template>
  <div class="h-screen w-full flex justify-center items-center bg-primary">
    <Wrapper class="max-w-lg">
      <form
        v-if="!emailSent && !recovery"
        @submit.prevent="sendEmail"
        class="w-full"
      >
        <router-link to="/" class="inline-block w-full text-left mb-6 mr-auto">
          <a-icon type="left" class="mr-3 text-gray-500" />Volver al inicio
        </router-link>
        <h2 class="mb-5 text-2xl text-gray-700">
          <b>¿Olvidó su contraseña?</b>
        </h2>
        <p class="mb-8 text-sm">
          Ingrese el correo electrónico asociado a su cuenta. Recibirá un correo
          con las instrucciones para restablecer su contraseña.
        </p>
        <BaseInput
          type="text"
          label="Ingrese su correo electrónico"
          class="mb-5"
          placeholder="Ej: pepe@perez.com"
          v-model="email"
          autocomplete="off"
        />
        <p v-if="feedback" class="mb-4 text-xs text-red-500">{{ feedback }}</p>
        <Button type="submit" :block="true" :loadung="true"> Enviar </Button>
      </form>
      <div v-if="emailSent" class="w-full">
        <h2 class="mb-6 text-2xl text-gray-700">
          <b>¡Correo electrónico enviado!</b>
        </h2>
        <p class="mb-14 text-sm">
          Hemos enviado a su correo electrónico las instrucciones para
          restablecer de su contraseña.
        </p>
        <span class="block h-px w-full bg-gray-400"></span>
        <p class="mt-5 text-xs text-gray-600">
          ¿No recibió el correo? Recuerde revisar su carpeta de correo no
          deseado.
        </p>
        <b
          class="text-xs cursor-pointer"
          :class="{
            'text-blue': !disableResend,
            'text-gray-500 pointer-events-none': disableResend,
          }"
          @click="resendEmail"
          >Realizar nuevamente la solicitud
          <span class="text-gray-500">{{ counter }}</span></b
        >
      </div>
      <form v-if="recovery" @submit.prevent="sendRecovery">
        <router-link to="/" class="inline-block w-full text-left mb-6 mr-auto">
          <a-icon type="left" class="mr-3 text-gray-500" />Volver al inicio
        </router-link>
        <h2 class="mb-3 text-2xl text-left text-gray-700">
          <b>Restablecer contraseña</b>
        </h2>
        <p class="mb-5 text-left text-sm">
          Ingrese el código de verificación y su nueva contraseña.
        </p>
        <BasePassword
          label="Nueva contraseña"
          placeholder="*********"
          v-model="password"
          autocomplete="new-password"
        />
        <BasePassword
          label="Verficar nueva contraseña"
          placeholder="*********"
          v-model="verifyPassword"
          autocomplete="new-password"
        />
        <p v-if="feedback" class="mt-4 text-xs text-red-500">{{ feedback }}</p>
        <Button type="submit" :block="true" :loadung="true" class="mt-5">
          Restablecer contraseña
        </Button>
      </form>
    </Wrapper>
  </div>
</template>

<script>
import {
  getAuth,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from 'firebase/auth'
export default {
  data() {
    return {
      emailSent: false,
      email: '',
      code: '',
      password: '',
      verifyPassword: '',
      loading: false,
      feedback: '',
      counter: '',
      disableResend: false,
      recovery: false,
    }
  },
  beforeDestroy() {
    this.emailSent = false
  },
  methods: {
    async sendEmail() {
      if (this.invalidSendEmailForm()) return
      this.loading = true
      try {
        const auth = getAuth()
        await sendPasswordResetEmail(auth, this.email)
        this.emailSent = true
      } catch (error) {
        this.feedback = 'El correo electrónico no existe'
        this.loading = false
      }
    },
    async resendEmail() {
      await this.sendEmail()
      this.timer()
    },
    async sendRecovery() {
      this.code = this.$route.query.oobCode
      if (this.invalidRecoveryForm()) return
      this.loading = true
      try {
        const auth = getAuth()
        await confirmPasswordReset(auth, this.code, this.password)
        this.$showNotification(
          'success',
          'Se actualizó correctamente la contraseña'
        )
        this.$router.push('/login')
      } catch (error) {
        this.feedback = 'Ocurrió un error al restablecer la contraseña'
        this.loading = false
      }
    },
    timer() {
      this.disableResend = true
      this.counter = 60
      let interval = setInterval(() => {
        if (this.counter < 1) {
          clearInterval(interval)
          this.counter = ''
          this.disableResend = false
        } else {
          this.counter = this.counter - 1
        }
      }, 1000)
    },
    invalidSendEmailForm() {
      this.feedback = null
      if (this.$validator.isEmpty(this.email)) {
        this.feedback = 'Ingrese un correo electrónico'
        return true
      }
      return false
    },
    invalidRecoveryForm() {
      this.feedback = null
      if (
        this.$validator.isEmpty(this.code) ||
        this.$validator.isEmpty(this.password)
      ) {
        this.feedback = 'Verifique la informacion'
        return true
      }
      if (this.password != this.verifyPassword) {
        this.feedback = 'Las contraseñas no coinciden'
        return true
      }
      if (this.password.length < 6) {
        this.feedback = 'Las contraseñas debe contener mínimo 6 caracteres'
        return true
      }
      return false
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(value) {
        if (value !== '/recovery/restore') {
          this.recovery = false
          return
        }

        this.email = this.$route.query.email
        if (this.email === '') {
          this.$router.push('/recovery')
          return
        }
        this.recovery = true
      },
    },
  },
}
</script>

<style></style>
